<script>

import router from '../../index'

export default {
  created() {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('user')
    router.push('/login');
  }
}
</script>
